import React, { useEffect } from 'react';

const Link = () => {
  useEffect(() => {
    window.location.replace('https://t.me/+SxokRaGWu7VlNjdk');
  }, []);
  return <></>;
};

export default Link;
