import React, { useState } from 'react';
import { useEffect, useContext } from 'react';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import CardImage from '../../Sass/img/hand1.png';
import { useNavigate } from 'react-router';
import { ClockLoader } from 'react-spinners';

export default function CardActivate2() {
  const roleContext = useContext(UserRoleContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay of 2000 milliseconds (2 seconds)
    const delay = 2000;

    const timer = setTimeout(() => {
      // Set isLoading to false after the delay
      setLoading(false);
    }, delay);

    // Clear the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  const override = {
    display: 'block',
    margin: '0 auto',
  };
  // const redirect = () => {
  //   console.log('rolecontext', roleContext)
  //   if (
  //     !roleContext.card_applied &&
  //     (roleContext.card_status === true || roleContext.card_status === 'paid')
  //   ) {
  //     navigate('/card-form')
  //   }
  // }

  // useEffect(() => {
  //   setInterval(() => {
  //     redirect()
  //   }, 3000)
  // }, [roleContext])

  return (
    <div className='container py-4'>
      {loading ? (
        <div className='row' style={{ height: '500px' }}>
          <div className='col-12 text-center my-auto d-flex justify-content-center'>
            <div className='m-auto'>
              <ClockLoader color='black' size={86} override={override} />
            </div>
          </div>
        </div>
      ) : (
        <div className='row justify-content-center pt-6'>
          <div className='col-lg-6 mb-lg-0 mb-4 d-flex flex-column align-items-center justify-content-center'>
            <img src={CardImage} alt='' className='img-fluid' />
            <h3 className='text-center activetext pt-4'>
              Thank you for your application!
            </h3>
            <h5
              style={{
                color: '#000',
                fontSize: '18px',
                margin: '10px 0',
                fontWeight: 'bold',
              }}
            >
              Your KYC has been SUBMITTED
            </h5>
            <p style={{ color: '#AAAAAA', textAlign: 'center' }}>
              Thank you for kyc. As soon as your KYC is approved we prepare the
              delivery of your THE ONE CARD in the next 4-6 weeks.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
