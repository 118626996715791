import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import { getCookie } from '../../Utils/cookieHandling';
import { CardSubmitInitialValues } from '../../Utils/initialValues';
import { cardSubmitSchema } from '../../Utils/validations';
import Button from '../../Components/Common/Button/Button';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { URL } from '../../Utils/url';
import OtpInput from 'react-otp-input';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import Compress from 'compress.js';
import { RotatingLines } from 'react-loader-spinner';
import { ClockLoader } from 'react-spinners';

export default function CardSubmit() {
  const [state, setState] = useState({
    number1: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const roleContext = useContext(UserRoleContext);
  const compress = new Compress();
  const [file, setFile] = useState(null);

  const navigate = useNavigate();

  const handlefileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const cardSubmitApi = async () => {
    setIsLoading(true);
    if (!file) {
      toast.error('Please upload the image');
      return;
    }
    if (state.number1.length !== 16) {
      toast.error('Please enter the card number');
      return;
    }

    var data = JSON.stringify({
      jdbCardNumber1: state.number1.toString(),
      card_activated: '1',
    });

    const formData = new FormData();

    formData.append('card_img', file);

    formData.append('userData', JSON.stringify(data));
    var config = {
      method: 'put',
      url: `${URL}/users/verifyCard/${getCookie('primary')}`,
      headers: {
        Authorization: getCookie('token'),
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          navigate('/cardisactivate');
          setIsLoading(false);
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log('ER', error);
        toast.error(error.response.data);
        setIsLoading(false);
      });
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay of 2000 milliseconds (2 seconds)
    const delay = 2000;

    const timer = setTimeout(() => {
      // Set isLoading to false after the delay
      setLoading(false);
    }, delay);

    // Clear the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  const override = {
    display: 'block',
    margin: '0 auto',
  };

  return (
    <div className=' card-form card-form-2   p-md-6'>
      {loading ? (
        <div className='row' style={{ height: '500px' }}>
          <div className='col-12 text-center my-auto d-flex justify-content-center'>
            <div className='m-auto'>
              <ClockLoader color='black' size={86} override={override} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <h6 className='ms-2 mb-0  h-white headt-1 mb-3'>Card Activation</h6>
          <div className='row '>
            <div className='col-lg-6 mb-lg-0 mb-4'>
              <div className='card z-index-2'>
                <div className='card-body p-md-6 pt-5 pb-6'>
                  <h6 className='ms-2 mb-0 text-center h-white3 mb-3'>
                    Input your 16 digit card number
                  </h6>

                  <div className='row justify-content-center '>
                    <div className='col-md-12  p-4 pt-1 mb-0 '>
                      {/* <div className=" d-flex justify-content-center "> */}
                      {/* <input
                      value={state.number}
                      onChange={(e) => {
                        setState({ ...state, number: e.target.value });
                      }}
                    /> */}
                      {/* <form> */}
                      <OtpInput
                        value={state.number1}
                        onChange={(e) => {
                          setState({ ...state, number1: e });
                        }}
                        numInputs={16}
                        inputStyle={{
                          marginLeft: '20px',
                          width: '20px',
                          color: 'black',
                          border: 'none',
                          borderBottom: '1px solid black',
                          backgroundColor: 'transparent',
                        }}
                        containerStyle={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                        separator={<span></span>}
                      />
                      {/* </form> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <p className='' style={{ textAlign: 'right' }}>
                Note: Wait patiently for your card to arrive.
              </p>
            </div>
            <div className='col-lg-6 mb-lg-0 mb-4'>
              {roleContext.card_active_reject === 'Rejected' && (
                <p className='' style={{ textAlign: 'center', color: 'red' }}>
                  Your submission has been rejected. Please upload a correct
                  selfie
                </p>
              )}
              <div className='card z-index-2'>
                <div className='card-body p-md-6 pt-4 pb-0'>
                  <h6 className='ms-2 mb-0 text-center h-white3 mb-3'>
                    Upload a clear selfie of you holding the card and passport
                    bio page
                  </h6>
                  <div className='row d-flex  align-items-center justify-content-center '>
                    <div className='col-md-12  p-4 pt-2 mb-0 '>
                      <form>
                        <div className='row pb-3 mb-4'>
                          <div className='d-flex align-items-center justify-content-center'>
                            <input
                              type='file'
                              className='filepond'
                              name='filepond'
                              onChange={async (e) => {
                                handlefileChange(e);
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=' justify-content-center text-center pt-4 pb-0'>
              {isLoading ? (
                <button className='btn btns'>
                  <RotatingLines
                    strokeColor='white'
                    strokeWidth='5'
                    animationDuration='0.75'
                    width='30'
                    visible={true}
                  />
                </button>
              ) : (
                <Button
                  type='submit'
                  className='btn btns'
                  text={'Submit'}
                  clicked={cardSubmitApi}
                />
              )}
            </div>
          </div>
          <div className='row my-4'></div>
        </>
      )}
    </div>
  );
}
