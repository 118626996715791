import './App.css';
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
  useLocation,
} from 'react-router-dom';
import Home from './Screens/Home/Home';
import CardForm from './Screens/CardForm/CardForm';
import {
  UserRoleContext,
  UserRoleContextConsumer,
} from './Utils/UserAuthorization';
import React, { useContext, useEffect } from 'react';
import HeaderNavigator from './Components/Header/HeaderNavigator';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardActivate from './Screens/CardForm/CardActivate';
import CardIsActivate from './Screens/CardForm/CardIsActivate';
import Landing from './Screens/Landing/Landing';
import CardSubmit from './Screens/CardForm/CardSubmit';
import CardLoad from './Screens/CardForm/Cardload';
import Dopple from './Screens/Dopple/Dopple';
import CardPurchase from './Screens/CardPurchase/CardPurchase';
import CardLoadHome from './Screens/Home/cardLoadHome';
import CardActivate2 from './Screens/CardForm/CardActive2';
import { MetaMaskProvider, useMetaMask } from 'metamask-react';
import AppLogout from './Screens/Logout';
import Dopple1 from './Screens/Dopple/Dopple1';
import Link from './Screens/Link';
import { getCookie } from './Utils/cookieHandling';
import LogRocket from 'logrocket';

function App() {
  const location = useLocation();
  const roleContext = useContext(UserRoleContext);

  const partner_name =
    location?.pathname === '/' ? '/affiliate' : `${location?.pathname}`;

  const notLoggedIn = (
    <Routes>
      <Route path='/login' element={<Home />} exact />
      <Route path='/affiliate' element={<Home />} exact />
      <Route path='/register' element={<Home />} exact />
      <Route path='/login/:id' element={<Dopple />} exact />
      <Route path='/staff' element={<Dopple1 />} exact />
      {/* <Route path='/staff/:id' element={<Dopple1 />} exact /> */}
      <Route path='/:id' element={<Dopple />} exact />
      <Route path='/:id/login' element={<Home />} exact />
      <Route index element={<Landing partner={partner_name} />} exact />
      <Route path='/links/qrcode' element={<Link />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
  const loggedIn = (
    <div className='h-100 home-container'>
      <AppLogout>
        <HeaderNavigator />
        <Routes>
          <Route path='/card-form' element={<CardForm />} exact />
          <Route path='/dashboard' element={<Home />} exact />
          <Route path='/card-load-home' element={<CardLoadHome />} exact />
          <Route path='/' element={<Home />} exact />
          <Route path='/card-process' element={<CardActivate2 />} exact />
          <Route path='/card-activate' element={<CardActivate />} exact />
          <Route path='/cardisactivate' element={<CardIsActivate />} exact />
          <Route path='/card-submit' element={<CardSubmit />} exact />
          <Route path='/card-load' element={<CardLoad />} exact />
          <Route path='/cardpurchase' element={<CardPurchase />} exact />

          <Route path='/links/qrcode' element={<Link />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </AppLogout>
    </div>
  );

  useEffect(() => {
    if (roleContext.isLoggedIn) {
      LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
      LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
        name: getCookie('primary'),
        email: 'theone',
      });
    }
  }, [roleContext.isLoggedIn]);

  return (
    <>
      <MetaMaskProvider>
        <UserRoleContextConsumer>
          {(value) =>
            value?.isLoggedIn ||
            (localStorage.getItem('token') && getCookie('primary'))
              ? loggedIn
              : notLoggedIn
          }
        </UserRoleContextConsumer>
        <ToastContainer limit={1} autoClose={2000} />
      </MetaMaskProvider>
    </>
  );
}

export default App;
