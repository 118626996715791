import { React, useEffect } from 'react';

import './Dopple.scss';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { URL } from '../../Utils/url';
import Landing from '../Landing/Landing';

function Dopple1() {
  const navigate = useNavigate();
  useEffect(() => {
    partnerCheck();
  }, []);
  const { id } = useParams();
  const partner_name = id ? id : '';
  const location = useLocation();
  const partner =
    location?.pathname === '/staff'
      ? '/affiliate'
      : `${location?.pathname.slice(6)}`;

  //partner check
  const partnerCheck = async () => {
    var config = {
      method: 'get',
      url: `${URL}/users/partner/` + partner_name,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
        } else {
          navigate('/login');
        }
      })
      .catch(function (error) {
        navigate('/login');
      });
  };
  return (
    <>
      <Landing partner={partner} staff={true} />
    </>
  );
}
export default Dopple1;
