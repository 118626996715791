export const loginInitialValues = {
  email: '',
  password: '',
};
export const RegisterInitialValues = {
  ...loginInitialValues,
  reenter: '',
};
export const CardSubmitInitialValues = {
  jdbCardNumber1: '',
  card_img: '',
};
export const CardFormInitialValues = {
  first_name: '',
  partnername: '',
  last_name: '',
  dob: '',
  card_type: '',
  email: '',
  contactNumber: '',
  mailing_address_line_1: '',
  address1: '',
  city: '',
  countryName: '',
  pincode: '',
  countryCode: '',
  emergencycountryCode: '',
  passport_id: '',
  nationality: '',

  gender: '',
  marital_status: '',
  emergency_contact_person: '',
  emergency_contact_telephone_number: '',
  place_of_id_issued: '',
  passport_expiry_date: '',
  id_type: 'Passport',
  id_no: '',
  id_issued_date: '',
  title: '',
  state: '',
  residence_city: '',
  residence_countryName: '',
  residence_pincode: '',
  residence_state: '',

  emboss_name: '',
};
export const forgotPasswordValues = {
  email: '',
  password: '',
};
