import React, { useEffect, useState } from 'react';
import CardImage from '../../Sass/img/tick1.png';
import { ClockLoader } from 'react-spinners';

export default function CardActivate() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay of 2000 milliseconds (2 seconds)
    const delay = 2000;

    const timer = setTimeout(() => {
      // Set isLoading to false after the delay
      setLoading(false);
    }, delay);

    // Clear the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  const override = {
    display: 'block',
    margin: '0 auto',
  };
  return (
    <div className='container py-4'>
      {loading ? (
        <div className='row' style={{ height: '500px' }}>
          <div className='col-12 text-center my-auto d-flex justify-content-center'>
            <div className='m-auto'>
              <ClockLoader color='black' size={86} override={override} />
            </div>
          </div>
        </div>
      ) : (
        <div className='row justify-content-center pt-6'>
          <div className='col-lg-6 mb-lg-0 mb-4 d-flex flex-column align-items-center justify-content-center'>
            <img src={CardImage} alt='' className='img-fluid' />

            <h3 className='text-center activetext pt-4'>CONGRATULATIONS!</h3>
            <h5
              style={{
                color: '#000',
                fontSize: '18px',
                margin: '10px 0',
                fontWeight: 'bold',
              }}
            >
              Your KYC has been ACCEPTED
            </h5>
            <p style={{ color: '#AAAAAA', textAlign: 'center' }}>
              Thank you for kyc. As soon as your KYC is approved we prepare the
              delivery of your THE ONE CARD in the next 4-6 weeks.
            </p>
          </div>
        </div>
      )}
      {/* <p className="notetext text-center pt-4">
          Note: Please wait patiently and we will notify you on the next steps
        </p> */}
    </div>
  );
}
