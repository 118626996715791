import React, { useEffect, useState, useContext } from 'react';
import meteor from '../../Sass/img/Meteor1.png';
import moon from '../../Sass/img/Moon1.png';
import Mars from '../../Sass/img/Mars1.png';
import marsplus from '../../Sass/img/marsp.png';
import './Landing.css';
import { Link, NavLink, useParams } from 'react-router-dom';
import LandingPageHeader from '../../Components/Header/LandingPageHeader';
import { Modal, Button } from 'react-bootstrap';
import Solswipe from '../../Sass/img/illustration.png';
import { getCookie, setCookie } from '../../Utils/cookieHandling';
import { useNavigate, useLocation } from 'react-router';
import { Connection } from '@solana/web3.js';
import LogRocket from 'logrocket';
import axios from 'axios';
import { URL } from '../../Utils/url';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { toast } from 'react-toastify';
import Solswipe1 from '../../Sass/img/title.png';
import { FaDiscord } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaTelegram } from 'react-icons/fa';

const Landing = (props) => {
  const [showModal, setShowModal] = useState('');
  let { solana } = window;
  const roleContext = useContext(UserRoleContext);
  const location = useLocation();
  const [clickData, setClickData] = useState(false);
  const navigate = useNavigate();
  const [disableForm, setDisableForm] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [modifiedDate, setModifiedDate] = useState(false);
  const [state, setState] = useState({
    modal: false,
    address: '',
    balance: '',
    isPhantomInstall: false,
    isConnected: false,
  });
  const [whiteListCheck, setWhiteListCheck] = useState(false);
  let rpc =
    'https://solana-mainnet.g.alchemy.com/v2/4wEd2OMWQ--ApUFV-5xm93SDYn2UcPmV';
  const connection = new Connection(rpc, 'confirmed');
  const [currentChain, setCurrentChain] = useState('');
  const [checkArray, setCheckarry] = useState([
    '/card-form',
    '/dashboard',
    '/card-load-home',
    '/card-process',
    '/card-activate',
    '/card-submit',
    '/card-load',
    '/cardpurchase',
    '/affiliate/dashboard',
    '/links/qrcode',
    '/cardisactivate',
  ]);

  const checkRoutes = () => {
    for (var i in checkArray) {
      if (checkArray[i] == location?.pathname.toString()) {
        navigate('/');
      }
    }
  };
  // useEffect(() => {
  //   $('ul').on('click', 'li', function () {
  //     var pos = $(this).index() + 2
  //     $('tr').find('td:not(:eq(0))').hide()
  //     $('td:nth-child(' + pos + ')').css('display', 'table-cell')
  //     $('tr').find('th:not(:eq(0))').hide()
  //     $('li').removeClass('active')
  //     $(this).addClass('active')
  //     $('tr').css('width', '100%')
  //   })

  //   let mediaQuery = window.matchMedia('(min-width: 640px)')
  //   mediaQuery.addListener(doSomething)

  //   const doSomething = (mediaQuery) => {
  //     if (mediaQuery.matches) {
  //       $('.sep').attr('colspan', 5)
  //     } else {
  //       $('.sep').attr('colspan', 2)
  //       $('.clk').trigger('click')
  //     }
  //   }
  //   doSomething(mediaQuery)
  // }, [])

  useEffect(() => {
    window.onload = (event) => {
      if (solana in window) {
        solana = window.solana;
      }
    };
    if (localStorage.getItem('token') && localStorage.getItem('primary')) {
      roleContext.updateContext({ isLoggedIn: true });
      checktoWhitelist();
    }

    if (props.staff) {
      localStorage.setItem('staff', true);
    }
  }, []);
  useEffect(() => {
    checkRoutes();
  }, [location.pathname]);

  if (solana) {
    solana.on('accountChanged', () => {
      solana.connect({ onlyIfTrusted: true }).then(() => {
        window.location.reload();
      });
    });
  }

  useEffect(() => {
    sessionStorage.setItem('balance', state.balance);
  }, [state.balance]);

  useEffect(() => {
    if (state.address != '') {
      connection.getBalance(solana.publicKey).then((res) => {
        setState((state) => ({
          ...state,
          balance: res,
        }));
      });
    }

    //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.address]);

  useEffect(() => {
    if (location.pathname === '/login' || location.pathname === '/register') {
      navigate('/');
    }
  }, [location]);

  const { id } = useParams();

  // login api
  const loginHandler = async () => {
    const partner = location?.pathname !== '/' && location?.pathname;
    const partner_name =
      location.pathname === '/'
        ? 'my.theonecard.io'
        : 'my.theonecard.io' + partner;
    if (getCookie('primary')) {
      try {
        const response = await fetch(`${URL}/users/loginWithAddress`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            partnerName: partner_name,
            userAddress: getCookie('primary'),
          }),
        }).then(async (response) => {
          const data = await response.json();

          if (response.status === 200) {
            setCookie('token', data.token);
            setCookie('email', data.email);
            setCookie('address', data.address);
            setCookie('partnerName', data.partnerName);
            setCookie('currentChain', 'SOL');
            setCurrentChain('SOL');
            roleContext.updateContext({ isLoggedIn: true });
            toast.success(data.message);

            if (location.pathname === '/') {
              await axios
                .put(`${URL}/users/updateaffiliate/${getCookie('primary')}`, {
                  affiliate: true,
                })
                .then((res) => {
                  if (res.status === 200) {
                    roleContext.updateContext({ affiliate: true });
                    console.log('affiliate updated');
                  } else {
                    toast.error(res.data.message);
                  }
                });
            }
          } else {
            toast.error(data.message);
          }
        });
      } catch (err) {
      } finally {
      }
    }
  };

  const checktoWhitelist = () => {
    axios
      .get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('primary')}`)
      .then(function (response) {
        if (response.status === 200) {
          roleContext.updateContext({ affiliate: response.data.affiliate });
          roleContext.updateContext({ card_type: response.data.card_type });
          roleContext.updateContext({
            jdbCardNumber1: response.data.jdbCardNumber1,
          });
          roleContext.updateContext({
            card_activated: response.data.card_activated,
          });
          roleContext.updateContext({
            card_active_reject: response.data.card_active_reject,
          });
          var now;
          let modifiedStatus = false;
          if (response.data.stakedate != null) {
            now = new Date(response.data.stakedate);
            now.setMinutes(now.getMinutes() + 30);
            now = new Date(now);
          }

          if (new Date() > now) {
            modifiedStatus = true;
          }

          roleContext.updateContext({ staking: response.data.staking });
          roleContext.updateContext({ stakingTime: modifiedStatus });
          roleContext.updateContext({
            approveStacking: response.data.stakeapprove,
          });

          if (
            response.data.jdbCardNumber1?.length === 16 &&
            response.data.card_activated === 2
          ) {
            setDisableForm(false);
          } else {
            setDisableForm(true);
          }

          if (response.data.card_applied === 1) {
            roleContext.updateContext({ card_applied: true });
          }

          if (
            response.data.kycStatus === '1' ||
            response.data.kycStatus === 1
          ) {
            roleContext.updateContext({ card_purchase: true });
          }
          if (response.data.card_activated === 2) {
            roleContext.updateContext({
              card_activated: response.data.card_activated,
            });
          }
          if (response.data.cardStatus === 'paid') {
            roleContext.updateContext({ card_status: true });
          }

          if (
            response.data.cardStatus === null &&
            response.data.card_type === 0
          ) {
            navigate('/cardpurchase');
          } else {
            return;
          }
        }
      })
      .catch(function (error) {});
  };

  const connectClickHandler = () => {
    if (getCookie('primary') && getCookie('token')) {
      return;
    }

    if (solana && solana.isPhantom) {
      solana
        .connect()
        .then(() => {
          const publicKey = solana.publicKey.toString();
          localStorage.setItem('primary', publicKey);
          whiteListHandler();
          loginHandler();
          setState((state) => ({
            ...state,
            address: publicKey,
            isConnected: true,
          }));
          LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
          LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
            name: publicKey,
            email: 'theone',
          });
        })
        .catch((err) => {
          console.error(err, 'fsgvb');
          toast.error(err.message);
        });
    } else {
      toast.error('Please install Phantom !');
      setState((state) => ({ ...state, modal: true }));
    }
  };

  const whiteListHandler = () => {
    if (!getCookie('primary')) {
      // updateAllCoins()
      connectClickHandler();
      return;
    }
    axios
      .get(
        `${URL}/users/whiteList?userAddress=${getCookie(
          'primary'
        )}&email=${getCookie('email')}`,
        {
          headers: {
            Authorization: getCookie('token'),
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          // updateAllCoins()
          setWhiteListCheck(true);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        toast.success(error);
      });
  };

  return (
    <div className='landing'>
      <LandingPageHeader />
      <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '>
        <div className='container-fluid m-0 p-0'>
          <div className='container py-4 '>
            <div className='row pt-6 justify-content-center align-items-center land-mob-row'>
              <div className='col-lg-8 col-md-6 mb-lg-0 mb-4'>
                <div className='col-lg-8 z-index-2  textcon'>
                  <h3 className='mb-0   headtext1'>THE ONE CRYPTO</h3>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center position-relative'>
                      <h3 className='mb-0   headtext1 '>DEBIT CARD</h3>
                      <div className='headtextcon'></div>
                    </div>
                  </div>
                  <div className='betacon'>Beta Program</div>
                  <p className='h-white color-head-landing-pt'>
                    THE ONE is not just a crypto debit card, it's a holistic
                    financial tool that's redefining how we perceive and use
                    money, bridging the gap between the worlds of fiat and
                    crypto currencies, and paving the way for a truly
                    decentralized financial future.
                  </p>
                  <div className='landlogin'>
                    <Link
                      to={
                        props.partner === '/affiliate'
                          ? '/affiliate'
                          : `${props.partner}/login`
                      }
                    >
                      <button type='button' className='loginbtn pr-3'>
                        Login
                      </button>
                    </Link>
                  </div>
                  {/* <button type="button" className="btn  btns-w btn-sign-landing1">
                  Learn More{" "}
                </button> */}
                </div>
              </div>
              <div className='col-lg-4 col-md-6   mb-lg-0 mb-4  mb-sm-5'>
                <div className=' z-index-2 logoimgcon'>
                  <img
                    src={Solswipe}
                    alt='cripto-debitcard'
                    className='img-fluid logoimg'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='container-fluid m-0 mt-5 px-0 sm-px-0 pt-4'>
            <div className='container justify-content-center text-center'>
              <h3
                className='mb-0 p-0  h-white headtext2'
                style={{ textTransform: 'uppercase' }}
              >
                {' '}
                Beta launch card Details
              </h3>
            </div>

            <div className='container pt-5 mt-5'>
              <div className='row justify-content-center py-2 cardbg'>
                <div className='col-lg-6 col-md-6 mb-lg-0 mb-4'>
                  <div className='z-index-2'>
                    <div className=' z-index-2'>
                      <img
                        src={meteor}
                        alt='premium-black'
                        className='img-fluid bd-right'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-4 mb-lg-0 mb-4 txt-right-landing '>
                  <div className='z-index-2  d-flex align-items-center justify-content-center'>
                    <div className='cardcon1'>
                      <h3 className='my-4   txt-transporm text-center'>
                        METEOR CARD (PLASTIC)
                      </h3>

                      {/* <p className="h-white color-head-landing-pt hd-fnt">
                    <img src={shield} alt="premium-black" className="img-fluid" />
                    <span className="pr-5 hd hd-color"> Stake: </span> $200 USD
                  </p> */}
                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'> Loading Fees: </span> 5%
                      </p>

                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'> Monthly Limit: </span>{' '}
                        $80,000 USD
                      </p>
                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'>
                          {' '}
                          Daily ATM Withdrawal:{' '}
                        </span>{' '}
                        Without Genesis NFT $5000 ATM daily limit <br />
                      </p>
                      <p className='detail-fnt'>
                        {' '}
                        <span className='pr-5 f-sol'>
                          {' '}
                          Daily ATM Withdrawal:{' '}
                        </span>{' '}
                        With Genesis NFT No limit
                      </p>
                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol '>
                          {' '}
                          Available Quantity:{' '}
                        </span>{' '}
                        No Limit
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row justify-content-center py-2  cardbg'>
                <div className='col-lg-6 col-md-6 mb-lg-0 mb-4'>
                  <div className='z-index-2  d-flex align-items-center justify-content-center'>
                    <div className='cardcon1'>
                      <h3 className='my-4    txt-transporm text-center'>
                        MOON CARD (METAL)
                      </h3>

                      {/* <p className="h-white detail-fnt">
                      <img src={shield} alt="premium-black" className="img-fluid" />
                      <span className="pr-5 hd hd-color"> Stake: </span> $1,000 USD
                    </p> */}
                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'> Loading Fees: </span> 4.5%
                      </p>

                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'> Monthly Limit: </span>{' '}
                        $200,000 USD
                      </p>
                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'>
                          {' '}
                          Daily ATM Withdrawal:{' '}
                        </span>{' '}
                        No Limit
                      </p>
                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'>
                          {' '}
                          Available Quantity:{' '}
                        </span>{' '}
                        4200 pcs
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 mb-lg-0 mb-4 txt-right-landing'>
                  <div className=' z-index-2 ' style={{ position: 'relative' }}>
                    <img
                      src={moon}
                      alt='premium-black'
                      className='img-fluid bd-left'
                    />
                    {/* <div className="cardoverlay" >
                          <h3 className="text-center" style={{color:'#fff'}}>COMING SOON</h3>
                      </div> */}
                  </div>
                </div>
              </div>
              <div className='row justify-content-center py-2 cardbg'>
                <div className='col-lg-6 col-md-6 mb-lg-0 mb-4'>
                  <div className='z-index-2'>
                    <div className='' style={{ position: 'relative' }}>
                      <img
                        src={Mars}
                        alt='premium-black'
                        className='img-fluid bd-right'
                      />
                      {/* <div className="cardoverlay1" >
                          <h3 className="text-center" style={{color:'#fff'}}>COMING SOON</h3>
                        </div> */}
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 mb-lg-0 mb-4 txt-right-landing'>
                  <div className='z-index-2  d-flex align-items-center justify-content-center'>
                    <div className='cardcon1'>
                      <h3 className='my-4   txt-transporm text-center'>
                        MARS CARD (METAL)
                      </h3>

                      {/* <p className="h-white detail-fnt">
                    <img src={shield} alt="premium-black" className="img-fluid" />
                    <span className="pr-5 hd hd-color"> Stake: </span> $1,000 USD
                  </p> */}
                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'> Loading Fees: </span> 4%
                      </p>

                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'> Monthly Limit: </span> No
                        Limit
                      </p>
                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'>
                          {' '}
                          Daily ATM Withdrawal:{' '}
                        </span>{' '}
                        No Limit
                      </p>
                      <p className='h-white detail-fnt'>
                        <span className='pr-5 f-sol'>
                          {' '}
                          Available Quantity:{' '}
                        </span>{' '}
                        420 pcs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-5 text-center footercon1 d-flex flex-column align-items-center justify-content-center'>
              <h3 className='footercon1text pt-8 pb-4'>
                POS AND ONLINE PURCHASES
              </h3>
              <div
                className='d-flex footer-cont align-items-center justify-content-center mt-4 mb-2 footercon5'
                footercon5
              >
                <div className='footermincon d-flex  flex-column align-items-start justify-content-center mt-2 '>
                  <p className='mintext1'>Bonus!</p>
                  <p className='mintext2'>$10 Initial Deposit</p>
                </div>
                <div className='footermincon d-flex flex-column align-items-start justify-content-center mt-2 '>
                  <p className='mintext1'>1st Year Annual Fee</p>
                  <p className='mintext2'>Annual Fee: $100</p>
                </div>
              </div>

              <div className='mt-2 pb-2 pb-3'>
                <Link to='/affiliate'>
                  <button type='button' className='btn applybtn'>
                    Apply
                  </button>
                </Link>
              </div>
            </div>

            <div
              className='d-flex flex-column align-items-center justify-content-center mt-5'
              style={{ width: '100%' }}
            >
              <img src={Solswipe1} width={'100px'} height={'50px'} />

              <div
                className='d-flex items-center justify-content-between my-4'
                width={'50%'}
              >
                <Link className='mx-2'>
                  <FaDiscord size={'40px'} color='#000' />
                </Link>
                <Link className='mx-2'>
                  <FaTwitter size={'40px'} color='#000' />
                </Link>
                <Link className='mx-2'>
                  <FaTelegram size={'40px'} color='#000' />
                </Link>
              </div>
              <p className='foottext1'>
                © Copyright 2023 - THE ONE. All right reserved
              </p>
              <div className='d-flex align-items-center'>
                <p className='mx-2'>Privacy Policy</p>
                <p className='mx-2'>Terms of use</p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div>
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
        >
          <Modal.Header>
            <Modal.Title>
              By using The One Card Loading Platform, I agree to the Important
              Disclaimer:
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='alert d-flex flex-column'>
              <span>
                - I am not a person or entity who resides in, are citizens of,
                are incorporated in , or have a registered office in any
                Prohibited Jurisdictions
              </span>

              <span>
                - I will not in the future access this site or use The One Card
                Loading Platform while located within any Prohibited
                Jurisdictions
              </span>

              <span>
                - I am lawfully permitted to access this site and use The One
                Card Loading Platform under the laws of the jurisdiction on
                which I reside and am located
              </span>

              <span>
                - I understand the risks associated with entering into and using
                The One Card Loading Platform
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='success'
              onClick={() => {
                setShowModal(false);
              }}
            >
              Agree And Proceed
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
export default Landing;
