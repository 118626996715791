import React, { Fragment, useState, useEffect, useContext } from 'react';
import LogoIcon from '../../Sass/img/Logoicon.svg';
import { getCookie } from '../../Utils/cookieHandling';
import axios from 'axios';
import ABI from '../../abi.json';
import { toast } from 'react-toastify';
import { URL } from '../../Utils/url';
import copy from '../../Sass/img/copy_address.png';
import premium from '../../Sass/img/Meteor1.png';
import silver from '../../Sass/img/Moon1.png';
import golden from '../../Sass/img/Mars1.png';
import marsplus from '../../Sass/img/marsp.png';
import { useNavigate, useLocation } from 'react-router';
import './CardPurchase.scss';

import { UserRoleContext } from '../../Utils/UserAuthorization';
import Card from '../../Sass/img/Card.png';
import { Connection } from '@solana/web3.js';
import * as spltoken from '@solana/spl-token';
import { ClockLoader } from 'react-spinners';

function CardPurchase(props) {
  const roleContext = useContext(UserRoleContext);
  const navigate = useNavigate();
  let { solana } = window;
  const [radioClick, setRadioCheck] = useState('0');
  const [cardType, setCardType] = useState('Meteor Card');
  const [whiteListCheck, setWhiteListCheck] = useState(true);
  const [state, setState] = useState({
    modal: false,
    address: '',
    balance: '',
    isPhantomInstall: false,
    isConnected: false,
  });
  const [redeemCode, setRedeemCode] = useState('');
  const [buttonName, setButtonName] = useState(null);
  const [isStaff, setIsstaff] = useState(null);
  let rpc =
    'https://solana-mainnet.g.alchemy.com/v2/4wEd2OMWQ--ApUFV-5xm93SDYn2UcPmV';
  const connection = new Connection(rpc, 'confirmed');

  useEffect(() => {
    setIsstaff(localStorage.getItem('staff'));
  }, []);

  window.onload = () => {
    if ('solana' in window) {
      solana = window.solana;
    }
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay of 2000 milliseconds (2 seconds)
    const delay = 2000;

    const timer = setTimeout(() => {
      // Set isLoading to false after the delay
      setLoading(false);
    }, delay);

    // Clear the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  const override = {
    display: 'block',
    margin: '0 auto',
  };

  useEffect(() => {
    if (solana) {
      initialize();
      checktoWhitelist();
    }
    // updateAllCoins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solana]);

  useEffect(() => {
    sessionStorage.setItem('balance', state.balance);
  }, [state.balance]);

  useEffect(() => {
    if (state.address != '') {
      connection.getBalance(solana.publicKey).then((res) => {
        setState((state) => ({
          ...state,
          balance: res,
        }));
      });
    } else {
      setState((state) => ({
        ...state,
        balance: '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.address, state.network]);

  const initialize = () => {
    if (solana && solana.isPhantom) {
      setState((state) => ({
        ...state,
        isPhantomInstall: true,
      }));

      solana
        .connect({ onlyIfTrusted: true })
        .then((result) => {
          if (result) {
            const publicKey = solana.publicKey.toString();
            setState((state) => ({
              ...state,
              address: publicKey,
              isConnected: true,
            }));
          } else {
            setState((state) => ({
              ...state,
              isConnected: false,
            }));
          }
        })
        .catch((error) => {});

      if (solana) {
        solana.on('accountChanged', () => {
          solana.connect({ onlyIfTrusted: true }).then(() => {
            window.location.reload();
          });
        });
      }
    } else {
      console.warn('Phantom not installed!');

      setState((state) => ({
        ...state,
        isPhantomInstall: false,
      }));
    }
  };

  const checktoWhitelist = () => {
    axios
      .get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('primary')}`)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.address != null) {
            setWhiteListCheck(true);
          }
          if (response.data.cardStatus === 'paid') {
            navigate('/card-form');
          }
        }
      })
      .catch(function (error) {
        toast.error(error);
      });
  };

  const copyAddressToClipboard = async () => {
    // navigator.clipboard.writeText("0x13e5eC037A9EB4ffff66097372f874eC28fB7d68");
    // toast.success("BEP20 Payment Address copied to clipboard");
  };
  // const CheckPayment = async (value, name) => {
  //   try {
  //     let fromPublicKey = new PublicKey(solana.publicKey.toBase58());
  //     let ToAccount = new PublicKey(
  //       "AX6MQeVjUCfHDRzCYEHXyYC9fbFV8UA294M8wSWEZkBY"
  //     );
  //     let UsdcAddress = new PublicKey(
  //       "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
  //     );

  //     let USDC_Token = new spltoken.Token(
  //       connection,
  //       UsdcAddress,
  //       spltoken.TOKEN_PROGRAM_ID,
  //       fromPublicKey
  //     );

  //     const fromTokenAcc = await USDC_Token.getOrCreateAssociatedAccountInfo(
  //       fromPublicKey
  //     );

  //     const toTokenAcc = await USDC_Token.getOrCreateAssociatedAccountInfo(
  //       ToAccount
  //     );

  //     var transaction = await new Transaction().add(
  //       spltoken.Token.createTransferInstruction(
  //         spltoken.TOKEN_PROGRAM_ID,
  //         fromTokenAcc.address,
  //         toTokenAcc.address,
  //         fromPublicKey,
  //         [],
  //         value * 1000000
  //       )
  //     );
  //     let latestBlockHash = await connection.getLatestBlockhash();
  //     transaction.recentBlockhash = await latestBlockHash.blockhash;
  //     transaction.feePayer = fromPublicKey;

  //     if (transaction) {
  //       console.log("Txn created");
  //     }
  //     let signed = await solana.signTransaction(transaction);
  //     let signature = await connection.sendRawTransaction(signed.serialize());
  //     await connection
  //       .confirmTransaction({
  //         signature,
  //         lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
  //         blockhash: latestBlockHash.blockhash,
  //       })
  //       .then((res) => {
  //         axios
  //           .post(
  //             `${URL}/users/cardPayment/${getCookie("phantomId")}`,
  //             {
  //               cardStatus: "paid",
  //               cardpurchase_txhash: `https://solscan.io/tx/${signature}`,
  //               card_type: name,
  //               card_purchased_amount: `${value} USDC`,
  //             },
  //             {
  //               headers: {
  //                 Authorization: getCookie("token"),
  //               },
  //             }
  //           )
  //           .then(function (response) {
  //             if (response.status === 200) {
  //               localStorage.setItem("cardStatus", "paid");
  //               localStorage.setItem("cardName", name);
  //               toast.success(response.data.message);
  //               navigate("/card-form");
  //               setButtonName("Paid");
  //             } else {
  //               toast.error("something went wrong");
  //             }
  //           })
  //           .catch(function (error) {
  //             toast.error(error.data.message);
  //           });
  //       });
  //   } catch (e) {
  //     console.log(e);
  //     toast.error("transaction Failed");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (cardType === 'Meteor Card') {
      toast.error('Select a Card type');
      return;
    }
    const code1 =
      cardType === 'Meteor Card upi'
        ? 'mu'
        : cardType.slice(0, 2).toLowerCase();
    const code2 = redeemCode.slice(0, 2).toLowerCase();

    if (code2 !== 'ma' && code2 !== 'mo' && code2 !== 'me' && code2 !== 'mu') {
      toast.error('Incorrect code');
      return;
    }

    if (code1 === code2) {
      const body = {
        code: redeemCode,
        card_type: cardType,
        cardStatus: 'paid',
      };
      console.log(body);
      try {
        await axios
          .post(
            `${URL}/users/redeemcodes_cardpayment/${getCookie('primary')}`,
            body,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: getCookie('token'),
              },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              roleContext.updateContext({ card_type: cardType });
              localStorage.setItem('cardStatus', 'paid');
              localStorage.setItem('cardName', cardType);
              toast.success(response.data.message);
              navigate('/card-form');
              setButtonName('Paid');
            } else {
              toast.error('something went wrong');
            }
          });
      } catch (e) {
        console.log(e);
        toast.error(e.response.data.message);
      }
    } else {
      toast.error(`Please select correct card type`);
    }
  };
  function getFirstLetters(str) {
    const firstLetters = str
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')
      .toLowerCase();

    return firstLetters;
  }
  const handleSubmit1 = async (e) => {
    e.preventDefault();

    const code1 = getFirstLetters(cardType);
    const code2 = redeemCode.slice(0, 3).toLowerCase();

    if (code2 !== 'mpp' && code2 !== 'mpm') {
      toast.error('Incorrect code');
      return;
    }
    if (code1 === code2) {
      const body = {
        code: redeemCode,
        card_type: cardType,
        cardStatus: 'paid',
      };
      try {
        await axios
          .post(
            `${URL}/users/staff_redeemcodes_cardpayment/${getCookie(
              'primary'
            )}`,
            body,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: getCookie('token'),
              },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              roleContext.updateContext({ card_type: cardType });
              localStorage.setItem('cardStatus', 'paid');
              localStorage.setItem('cardName', cardType);
              toast.success(response.data.message);
              navigate('/card-form');
              setButtonName('Paid');
            } else {
              toast.error('something went wrong');
            }
          });
      } catch (e) {
        console.log(e);
        toast.error(e.response.data.message);
      }
    } else {
      toast.error(`Please select correct card type`);
    }
  };

  return (
    <form>
      <div className=' pb--30 new-data'>
        <div className='container py-4'>
          {loading ? (
            <div className='row' style={{ height: '500px' }}>
              <div className='col-12 text-center my-auto d-flex justify-content-center'>
                <div className='m-auto'>
                  <ClockLoader color='black' size={86} override={override} />
                </div>
              </div>
            </div>
          ) : (
            <div className='row my-4'>
              <div className='col-lg-12 mb-lg-0 mb-4'>
                <div className='card z-index-2'>
                  <div className='card-body p-md-3 p-sm-0 '>
                    <h6 className='ms-2 mb-0 text-center h-white headt-1  pt-3'>
                      Card Selection
                    </h6>
                    <h6
                      className='ms-2 mb-0 text-center h-white   py-3'
                      style={{ fontSize: '18px', fontWeight: 500 }}
                    >
                      Select the card you've purchased <br />
                      Enter the Voucher code to recieve your personalized card.
                    </h6>
                    <form>
                      <div className='row p-4 pt-0 justify-content-center'>
                        {isStaff ? (
                          <div
                            className={
                              radioClick === '4'
                                ? 'col-md-6  pt-3'
                                : 'col-md-6  pt-3 '
                            }
                          >
                            <div className='row d-flex align-items-center md-row'>
                              <div className='col-md-8 col-sm-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <div className='row d-flex align-items-center justify-content-center'>
                                    <div className='col-md-1 col-sm-12 d-flex align-items-center justify-content-center'>
                                      <div>
                                        <input
                                          type='radio'
                                          className='custom-control-input'
                                          id='customCheck5'
                                          name='example5'
                                          checked={radioClick === '5'}
                                          onChange={(e) => {
                                            setRadioCheck(
                                              e.target.checked
                                                ? '5'
                                                : radioClick
                                            );
                                            setCardType('Mars plus plastic');
                                          }}
                                        />
                                        <label for='customCheck5'></label>
                                      </div>
                                    </div>
                                    <div
                                      className='col-md-11 col-sm-12 d-flex justify-content-center'
                                      style={{ position: 'relative' }}
                                    >
                                      <label
                                        className='custom-control-label'
                                        for='customCheck5'
                                      >
                                        <img src={marsplus} width='100%' />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-12 new-btn text-center d-flex flex-column align-items-center justify-content-center'>
                                <p className='h-white2'>Mars plus (plastic)</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              radioClick == '0'
                                ? 'col-md-6  pt-3'
                                : 'col-md-6  pt-3 '
                            }
                          >
                            <div className='row '>
                              <div className='col-md-8 col-sm-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <div className='row d-flex align-items-center justify-content-center'>
                                    <div className='col-md-1 col-sm-12 d-flex align-items-center justify-content-center'>
                                      <div>
                                        <input
                                          type='radio'
                                          className='custom-control-input'
                                          id='customCheck1'
                                          name='example1'
                                          checked={radioClick == '0'}
                                          onChange={(e) => {
                                            setRadioCheck(
                                              e.target.checked
                                                ? '0'
                                                : radioClick
                                            );
                                            setCardType('Meteor Card');
                                          }}
                                        />
                                        <label for='customCheck1'></label>
                                      </div>
                                    </div>
                                    <div className='col-md-11 col-sm-12 d-flex justify-content-center'>
                                      <label
                                        className='custom-control-label'
                                        for='customCheck1'
                                      >
                                        <img src={premium} width='100%' />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-12 new-btn text-center d-flex flex-column align-items-center justify-content-center'>
                                <p className='h-white2'>Meteor Card</p>
                                <select
                                  className='form-control form-control-2 mb-2'
                                  id='exampleFormControlSelect1'
                                  onChange={(e) => {
                                    if (e.target.value == 'Select Type') {
                                      return;
                                    }

                                    setCardType(
                                      'Meteor Card' + ' ' + e.target.value
                                    );
                                  }}
                                >
                                  <option>Select Type</option>
                                  <option value={'upi'}>
                                    UnionPay International
                                  </option>
                                  <option value={'visa'}>Visa</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        )}

                        {isStaff ? (
                          <div
                            className={
                              radioClick === '6'
                                ? 'col-md-6  pt-3'
                                : 'col-md-6  pt-3 '
                            }
                          >
                            <div className='row d-flex align-items-center md-row'>
                              <div className='col-md-8 col-sm-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <div className='row d-flex align-items-center justify-content-center'>
                                    <div className='col-md-1 col-sm-12 d-flex align-items-center justify-content-center'>
                                      <div>
                                        <input
                                          type='radio'
                                          className='custom-control-input'
                                          id='customCheck6'
                                          name='example6'
                                          checked={radioClick === '6'}
                                          onChange={(e) => {
                                            setRadioCheck(
                                              e.target.checked
                                                ? '6'
                                                : radioClick
                                            );
                                            setCardType('Mars plus metal');
                                          }}
                                        />
                                        <label for='customCheck6'></label>
                                      </div>
                                    </div>
                                    <div
                                      className='col-md-11 col-sm-12 d-flex justify-content-center'
                                      style={{ position: 'relative' }}
                                    >
                                      <label
                                        className='custom-control-label'
                                        for='customCheck6'
                                      >
                                        <img src={marsplus} width='100%' />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-12 new-btn text-center d-flex flex-column align-items-center justify-content-center'>
                                <p className='h-white2'>Mars plus (metal)</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              radioClick === '2'
                                ? 'col-md-6  pt-3'
                                : 'col-md-6  pt-3 '
                            }
                          >
                            <div className='row '>
                              <div className='col-md-8 col-sm-12 '>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <div className='row d-flex align-items-center justify-content-center'>
                                    <div className='col-md-1 col-sm-12 d-flex align-items-center justify-content-center'>
                                      <div>
                                        <input
                                          type='radio'
                                          className='custom-control-input'
                                          id='customCheck3'
                                          name='example3'
                                          checked={radioClick == '2'}
                                          onChange={(e) => {
                                            setRadioCheck(
                                              e.target.checked
                                                ? '2'
                                                : radioClick
                                            );
                                            setCardType('Moon Card');
                                          }}
                                        />
                                        <label for='customCheck3'></label>
                                      </div>
                                    </div>
                                    <div
                                      className='col-md-11 col-sm-12 d-flex justify-content-center '
                                      style={{
                                        position: 'relative',
                                      }}
                                    >
                                      <label
                                        className='custom-control-label'
                                        for='customCheck3'
                                      >
                                        <img src={silver} width='100%' />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-12 new-btn text-center d-flex flex-column align-items-center justify-content-center'>
                                <p className='h-white2'>Moon Card</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='row p-4 pt-0 '>
                        {!isStaff && (
                          <div
                            className={
                              radioClick === '3'
                                ? 'col-md-6  pt-3'
                                : 'col-md-6  pt-3 '
                            }
                          >
                            <div className='row d-flex align-items-center md-row'>
                              <div className='col-md-8 col-sm-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <div className='row d-flex align-items-center justify-content-center'>
                                    <div className='col-md-1 col-sm-12 d-flex align-items-center justify-content-center'>
                                      <div>
                                        <input
                                          type='radio'
                                          className='custom-control-input'
                                          id='customCheck4'
                                          name='example4'
                                          checked={radioClick === '3'}
                                          onChange={(e) => {
                                            setRadioCheck(
                                              e.target.checked
                                                ? '3'
                                                : radioClick
                                            );
                                            setCardType('Mars Card');
                                          }}
                                        />
                                        <label for='customCheck4'></label>
                                      </div>
                                    </div>
                                    <div
                                      className='col-md-11 col-sm-12 d-flex justify-content-center'
                                      style={{ position: 'relative' }}
                                    >
                                      <label
                                        className='custom-control-label'
                                        for='customCheck4'
                                      >
                                        <img src={golden} width='100%' />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-12 new-btn text-center d-flex flex-column align-items-center justify-content-center'>
                                <p className='h-white2'>Mars Card</p>
                              </div>
                            </div>
                          </div>
                        )}

                        <div
                          className={
                            isStaff
                              ? 'd-flex align-items-center justify-content-center'
                              : 'col-md-6 d-flex align-items-center justify-content-center'
                          }
                        >
                          <div className='row d-flex align-items-center justify-content-center'>
                            <div className='col-md-12 d-flex flex-column flex-lg-row  align-items-center justify-content-center'>
                              <h4 className='px-2 h-white3'>Redeem Code</h4>
                              <input
                                className='p-2 border-none rounded-3'
                                type={'text'}
                                placeholder='type here'
                                onChange={(e) => setRedeemCode(e.target.value)}
                                value={redeemCode}
                                style={{
                                  fontSize: '18px',
                                  fontWeight: 600,
                                  textAlign: 'center',
                                }}
                              />
                            </div>
                            <button
                              className=' stakebtns2 my-3 '
                              style={{ width: '40%' }}
                              onClick={isStaff ? handleSubmit1 : handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <span className='d-flex justify-content-center span-txt txt--white mb--20'>
          For every card purchased,you have recieved a voucher code via email
          that can be redeemed here.
        </span>
      </div>
    </form>
  );
}

export default CardPurchase;

{
  /* <div style={{position:'absolute',top:'45%',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',paddingRight:'20px'}}>
                                  <h2 className='cardtitle text-white' >COMING SOON</h2>
                                  </div> */
}

{
  /* <h3 className=' text-center' style={{position:'absolute',top:'40%',color:'#444'}}>COMING SOON</h3> */
}

{
  /* <h3 className='pt-0 mt-0 color-head'>
                              10000<span className='f-sol pad-l'>$USDC</span>
                            </h3> */
}
{
  /* 
                            <button
                              //  disabled="true"
                              type="button"
                              className="btn stakebtns2 p-3"
                              //  disabled={(roleContext.card_type === "Gold Metal") ? false : true}

                              onClick={() => {
                                if (radioClick !== "3") {
                                  toast.error("Please select Mars Card")
                                  return
                                }

                                CheckPayment(10000, "Mars Card")
                              }}
                            >
                              {buttonName &&
                              roleContext.card_type === "Mars Card"
                                ? "Paid"
                                : "CONFIRM PAYMENT"}
                            </button> */
}
