import { React, useEffect, useState } from 'react';
import HeaderNavigator from '../../Components/Header/HeaderNavigator';
import './Dopple.scss';
import axios from 'axios';

import { useNavigate, useLocation } from 'react-router';
import { NavLink, useParams } from 'react-router-dom';
import { URL } from '../../Utils/url';
import Landing from '../Landing/Landing';
import { toast } from 'react-toastify';
function Dopple() {
	const navigate = useNavigate();
	useEffect(() => {
		partnerCheck();
	}, []);
	const { id } = useParams();
	const partner_name = id ? id : '';

	const [checkArray, setCheckarry] = useState([
		'/card-form',
		'/dashboard',
		'/card-load-home',
		'/card-process',
		'/card-activate',
		'/card-submit',
		'/card-load',
		'/cardpurchase',
		'/affiliate/dashboard',
		'/links/qrcode',
	]);
	const getPartnerName = (location) => {
		console.log(location);
		for (var i in checkArray) {
			if (checkArray[i] == location.toString()) {
				console.log('called1');

				return '/affiliate';
			}
		}
		console.log('called2');
		return location;
	};
	const location = useLocation();
	const partner =
		location?.pathname === '/'
			? '/affiliate'
			: getPartnerName(location?.pathname);

	//partner check
	const partnerCheck = async () => {
		var config = {
			method: 'get',
			url: `${URL}/users/partner/` + partner_name,
			headers: {},
		};

		axios(config)
			.then(function (response) {
				if (response.status === 200) {
				} else {
					navigate('/login');
				}
			})
			.catch(function (error) {
				toast.error('Referral link doesnt exist');
				navigate('/login');
			});
	};
	return (
		<>
			<Landing partner={partner} />
		</>
	);
}
export default Dopple;
