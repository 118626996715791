import React from 'react';
import infinity from '../../Sass/img/Infinity.png';
import Solswipe from '../../Sass/img/LOGO1.svg';

const Footer = () => {
  return (
    <div className='flex bg-black align-items-center py-5'>
      <div className=' d-flex flex-column align-items-center justify-content-center'>
        <div className='d-flex align-items-center'>
          <img src={Solswipe} width={'80px'} height={'80px'} />
          <div
            style={{
              height: '32px',
              border: '1px solid #AAAAAA',
              margin: '0 20px',
            }}
          ></div>
          <h7 className='foottext'>Part of</h7>
          <img src={infinity} width={'30px'} height={'30px'} />
        </div>

        <p
          style={{
            fontFamily: 'N27',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'center',
            color: '#AAAAAA',
          }}
        >
          © Copyright 2023 - THE ONE. All right reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
