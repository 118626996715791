import React from 'react';
import CardImage from '../../Sass/img/onecards.png';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { getCookie } from '../../Utils/cookieHandling';
import { URL } from '../../Utils/url';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import './Cardload.scss';
import { ClockLoader } from 'react-spinners';

export default function CardIsActivate() {
  const [activated, setActivated] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay of 2000 milliseconds (2 seconds)
    const delay = 2000;

    const timer = setTimeout(() => {
      // Set isLoading to false after the delay
      setLoading(false);
    }, delay);

    // Clear the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  const override = {
    display: 'block',
    margin: '0 auto',
  };
  const checktoWhitelist = () => {
    axios
      .get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('primary')}`)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.card_active_reject === 'Activated') {
            setActivated(true);
          }
        }
      })
      .catch(function (error) {});
  };

  const activateUser = () => {
    axios
      .get(`${URL}/users/user_activated/${getCookie('primary')}`)
      .then(function (response) {
        toast.success('Card Activated Successfully');
        navigate('/');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    checktoWhitelist();
    setInterval(() => {
      checktoWhitelist();
    }, 5000);
  }, []);

  return (
    <div className='container text-center'>
      {loading ? (
        <div className='row' style={{ height: '500px' }}>
          <div className='col-12 text-center my-auto d-flex justify-content-center'>
            <div className='m-auto'>
              <ClockLoader color='black' size={86} override={override} />
            </div>
          </div>
        </div>
      ) : (
        <div className='row justify-content-center'>
          <div className='col-lg-6 mb-lg-0 mb-4 d-flex flex-column align-items-center justify-content-center'>
            <img
              src={CardImage}
              alt=''
              className='img-fluid'
              width={'65%'}
              height={'65%'}
            />

            {activated ? (
              <div className='d-flex align-items-center flex-column'>
                <h3 className='activetext'>CONGRATULATIONS!</h3>
                <h5
                  className='text-center  pt-2'
                  style={{
                    color: '#000',
                    fontSize: '18px',
                    margin: '10px 0',
                    fontWeight: 'bold',
                  }}
                >
                  Your card has been ACTIVATED
                </h5>
                <div
                  className='btn btn-dash'
                  style={{ marginTop: '20px' }}
                  onClick={activateUser}
                >
                  <span style={{ color: '#fff' }}>Proceed To Dashboard</span>
                </div>
              </div>
            ) : (
              <h5
                className='text-center activetext pt-2'
                style={{
                  color: '#000',
                  fontSize: '18px',
                  margin: '10px 0',
                  fontWeight: 'bold',
                }}
              >
                Your Card activation is being processed
              </h5>
            )}
          </div>
        </div>
      )}
      {!activated && (
        <p style={{ color: '#AAAAAA' }}>
          Check-in 24-48h to see if you have been approved.
        </p>
      )}
    </div>
  );
}
