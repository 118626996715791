import React, { Fragment, useState, useEffect, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import './Header.scss';
import Banding from '../../Sass/img/Branding.png';
import { useNavigate, useLocation } from 'react-router';
import { deleteAll, getCookie, setCookie } from '../../Utils/cookieHandling';
import axios from 'axios';
import { URL } from '../../Utils/url';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { toast } from 'react-toastify';
import getWeb3 from '../../Utils/getWeb3';
import ETH from '../../Sass/img/ETH.png';
import SOL from '../../Sass/img/Sol.png';
import { useMetaMask } from 'metamask-react';
import LOGO from '../../Sass/img/LOGO1.svg';
import { Connection } from '@solana/web3.js';
import LogRocket from 'logrocket';
import { isMobile } from 'react-device-detect';

export default function HeaderNavigator() {
  let { status, connect, ethereum, account } = useMetaMask();
  let { solana } = window;
  const roleContext = useContext(UserRoleContext);
  const location = useLocation();
  const [clickData, setClickData] = useState(false);
  const navigate = useNavigate();
  const [disableForm, setDisableForm] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [state, setState] = useState({
    modal: false,
    address: '',
    balance: '',
    isPhantomInstall: false,
    isConnected: false,
  });
  const [whiteListCheck, setWhiteListCheck] = useState(false);
  const [visible, setVisible] = useState(false);
  let rpc =
    'https://solana-mainnet.g.alchemy.com/v2/4wEd2OMWQ--ApUFV-5xm93SDYn2UcPmV';
  const connection = new Connection(rpc, 'confirmed');
  const [currentChain, setCurrentChain] = useState('');
  const [width, setWidth] = useState(window?.innerWidth);
  const isMobile = width <= 768;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const [checkArray, setCheckarry] = useState([
    '/card-form',
    '/dashboard',
    '/card-load-home',
    '/card-process',
    '/card-activate',
    '/card-submit',
    '/card-load',
    '/cardpurchase',
    '/affiliate/dashboard',
    '/partnerShip',
    '/distributor',
    '/cardisactivate',
  ]);
  const checkRoutes = () => {
    if (!getCookie('token')) {
      for (var i in checkArray) {
        if (checkArray[i] == location?.pathname.toString()) {
          navigate('/affiliate');
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
    checkRoutes();
  }, []);

  useEffect(() => {
    if (solana in window) {
      solana = window.solana;
    }
    if (localStorage.getItem('token') && getCookie('primary')) {
      roleContext.updateContext({ isLoggedIn: true });
      checktoWhitelist();
    }
  }, []);

  useEffect(() => {
    if (roleContext.isLoggedIn) {
      LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
      LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
        name: getCookie('primary'),
        email: 'theone',
      });
    }
  }, [roleContext.isLoggedIn]);

  if (solana) {
    solana.on('accountChanged', () => {
      solana.connect({ onlyIfTrusted: true }).then(() => {
        window.location.reload();
      });
    });
  }

  useEffect(() => {
    sessionStorage.setItem('balance', state.balance);
  }, [state.balance]);

  useEffect(() => {
    if (state.address != '') {
      connection.getBalance(solana.publicKey).then((res) => {
        setState((state) => ({
          ...state,
          balance: res,
        }));
      });
    }

    //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.address]);

  useEffect(() => {
    if (location.pathname === '/login' || location.pathname === '/register') {
      navigate('/');
    }
  }, [location]);

  const { id } = useParams();

  // login api
  const loginHandler = async () => {
    const partner =
      location?.pathname !== '/affiliate' && location?.pathname.slice(0, -6);

    const partner_name = 'my.theonecard.io';

    console.log(partner_name, 'naemeee');
    const body = JSON.stringify({
      partnerName: partner_name,
      userAddress: getCookie('phantomId') || getCookie('metamaskId'),
      domain_name: 'TheOne',
    });
    if (getCookie('phantomId') || getCookie('metamaskId')) {
      try {
        axios
          .post(`${URL}/users/loginWithAddress`, body, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(async (response) => {
            const { data } = response;

            if (response.status === 200) {
              setCookie('token', data.token);
              setCookie('email', data.email);
              setCookie('primary', data.address);
              setCookie('partnerName', data.partnerName);
              console.log(data.partnerName, 'partner name header');
              setCookie('second', data.secondAddress);

              setCurrentChain('SOL');
              roleContext.updateContext({ isLoggedIn: true });
              toast.success(data.message);

              if (location.pathname === '/affiliate') {
                await axios
                  .put(`${URL}/users/updateaffiliate/${getCookie('primary')}`, {
                    affiliate: true,
                  })
                  .then((res) => {
                    if (res.status === 200) {
                      roleContext.updateContext({ affiliate: true });
                      console.log('affiliate updated');
                    } else {
                      toast.error(res.data.message);
                    }
                  });
              }
              if (isMobile) {
                window.location.reload();
              }
            } else {
              toast.error(data.message);
            }
          })
          .catch((error) => {
            console.log('called1');
            localStorage.clear();
            setTimeout(() => {
              window.location.reload();
            }, 3000);
            toast.error(error.response.data);
          });
      } catch (error) {
        console.log('called2');
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        toast.error(error.response.data);
      }
    }
  };

  const networks = {
    eth: {
      chainId: '0x1',
      chainName: 'Ethereum',
      nativeCurrency: {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: ['https://mainnet.infura.io/v3/undefined'],
      blockExplorerUrls: ['https://etherscan.io'],
    },
    bsc: {
      chainId: `0x${Number(56).toString(16)}`,
      chainName: 'Binance Smart Chain Mainnet',
      nativeCurrency: {
        name: 'Binance Chain Native Token',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: ['https://bsc-dataseed.binance.org'],
      blockExplorerUrls: ['https://bscscan.com'],
    },

    // xdc: {
    //   chainId: `0x${Number(50).toString(16)}`,
    //   chainName: 'XinFin Network Mainnet',
    //   nativeCurrency: {
    //     name: 'XinFin',
    //     symbol: 'XDC',
    //     decimals: 18,
    //   },
    //   rpcUrls: ['https://rpc.xinfin.network'],
    //   blockExplorerUrls: ['https://xinfin.org'],
    // },
  };

  const changeNetwork = async ({ networkName }) => {
    if (
      localStorage.getItem('primary').slice(0, 2).toLocaleLowerCase() === '0x'
    ) {
      localStorage.setItem('currentChain', networkName.toUpperCase());
    } else {
      try {
        if (!window.ethereum) throw new Error('No crypto wallet found');
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              ...networks[networkName],
            },
          ],
        });
        setCurrentChain(networkName);
        connect().then((res) => {
          console.log(res[0], 'Metamask address');

          if (
            localStorage?.getItem('second') === 'null' ||
            localStorage?.getItem('second') === ''
          ) {
            localStorage.setItem('second', res[0]);
            localStorage.setItem('metamaskId', res[0]);
            localStorage.setItem('currentChain', networkName.toUpperCase());
            window.location.reload();
          } else if (
            res[0]?.toLowerCase() ===
            localStorage.getItem('second')?.toLowerCase()
          ) {
            localStorage.setItem('metamaskId', res[0]);
            localStorage.setItem('currentChain', networkName.toUpperCase());
            window.location.reload();
          } else {
            setCookie('currentChain', 'SOL');
            toast.error(
              `Please connect with the correct Secondary Address ${
                getCookie('second').slice(0, 4) +
                '...' +
                getCookie('second').substr(getCookie('second').length - 4)
              }`
            );
          }
        });
      } catch (err) {
        toast.error('Please Install Phantom');
        console.log(err);
      }
    }
  };

  const handleNetworkSwitch = async (networkName) => {
    if (
      localStorage.getItem('primary').slice(0, 2).toLocaleLowerCase() === '0x'
    ) {
      localStorage.setItem('currentChain', networkName.toUpperCase());
    } else {
      if (networkName.toLowerCase() === 'eth') {
        if (window.ethereum) {
          window.ethereum
            .request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x1' }],
            })
            .then(() => {
              localStorage.setItem('currentChain', networkName.toUpperCase());
              connect().then((res) => {
                console.log(res[0], 'Metamask address');
                if (
                  localStorage.getItem('second') === 'null' ||
                  localStorage.getItem('second') === ''
                ) {
                  localStorage.setItem('second', res[0]);
                  localStorage.setItem('metamaskId', res[0]);

                  localStorage.setItem(
                    'currentChain',
                    networkName.toUpperCase()
                  );
                  window.location.reload();
                } else if (
                  res[0]?.toLowerCase() ===
                  localStorage.getItem('second')?.toLowerCase()
                ) {
                  localStorage.setItem('metamaskId', res[0]);
                  localStorage.setItem(
                    'currentChain',
                    networkName.toUpperCase()
                  );
                  window.location.reload();
                } else {
                  setCookie('currentChain', 'SOL');
                  toast.error(
                    `Please connect with the correct Secondary Address ${
                      getCookie('second')?.slice(0, 4) +
                      '...' +
                      getCookie('second')?.substr(
                        getCookie('second')?.length - 4
                      )
                    }`
                  );
                }
              });
            })
            .catch((e) => {
              toast.error(e);
            });
        } else {
          toast.error('Please Install Metamask');
        }
        return;
      }
    }
    await changeNetwork({ networkName });
  };

  const handleNetworkSwitch2 = async (networkName) => {
    if (getCookie('primary')?.slice(0, 2).toLowerCase() !== '0x') {
      console.log('called');
      localStorage.setItem('currentChain', networkName.toUpperCase());
      localStorage.setItem('phantomId', getCookie('primary'));
    } else {
      if (networkName.toLowerCase() === 'sol') {
        if (solana && solana.isPhantom) {
          solana.connect().then(() => {
            const publicKey = solana.publicKey.toString();

            if (localStorage.getItem('second') === 'null') {
              localStorage.setItem('second', publicKey);
              return;
            }
            if (
              publicKey?.toLowerCase() ===
                localStorage.getItem('second')?.toLowerCase() ||
              localStorage.getItem('second') === 'null'
            ) {
              localStorage.setItem('second', publicKey);
              localStorage.setItem('phantomId', publicKey);

              localStorage.setItem('currentChain', networkName.toUpperCase());
              window.location.reload();
            } else {
              setCookie('currentChain', 'bsc');
              toast.error(
                `Please connect with the correct Secondary Address ${
                  getCookie('second')?.slice(0, 4) +
                  '...' +
                  getCookie('second')?.substr(getCookie('second')?.length - 4)
                }`
              );
            }
          });
        }
      }
    }
  };

  const checktoWhitelist = () => {
    axios
      .get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('primary')}`)
      .then(function (response) {
        if (response.status === 200) {
          roleContext.updateContext({ affiliate: response.data.affiliate });
          roleContext.updateContext({ card_type: response.data.card_type });
          localStorage.setItem('cardName', response.data.card_type);
          roleContext.updateContext({
            jdbCardNumber1: response.data.jdbCardNumber1,
          });
          roleContext.updateContext({
            ascii_value: response.data.ascii_value,
          });
          roleContext.updateContext({
            card_activated: response.data.card_activated,
          });
          roleContext.updateContext({
            card_active_reject: response.data.card_active_reject,
          });
          var now;
          let modifiedStatus = false;
          if (response.data.stakedate != null) {
            now = new Date(response.data.stakedate);
            now.setMinutes(now.getMinutes() + 30);
            now = new Date(now);
          }

          if (new Date() > now) {
            modifiedStatus = true;
          }

          roleContext.updateContext({ staking: response.data.staking });
          roleContext.updateContext({ stakingTime: modifiedStatus });
          roleContext.updateContext({
            approveStacking: response.data.stakeapprove,
          });

          if (
            response.data.jdbCardNumber1?.length === 16 &&
            response.data.card_activated === 2
          ) {
            setDisableForm(false);
          } else {
            setDisableForm(true);
          }

          if (response.data.card_applied === 1) {
            roleContext.updateContext({ card_applied: true });
          }

          if (
            response.data.kycStatus === '1' ||
            response.data.kycStatus === 1
          ) {
            roleContext.updateContext({ card_purchase: true });
          }
          if (response.data.card_activated === 2) {
            roleContext.updateContext({
              card_activated: response.data.card_activated,
            });
          }

          if (response.data.cardStatus === 'paid') {
            roleContext.updateContext({ card_status: true });
          }

          if (
            response.data.cardStatus === null &&
            response.data.card_type === 0
          ) {
            navigate('/cardpurchase');
          }

          if (
            response.data.affiliate &&
            (response.data.kycStatus === '1' ||
              response.data.kycStatus === 1) &&
            response.data.jdbCardNumber1 == null &&
            response.data.cardStatus === 'paid'
          ) {
            navigate('/card-process');
            return;
          }
          if (
            response.data.card_applied === 0 &&
            response.data.cardStatus === 'paid'
          ) {
            navigate('/card-form');
            return;
          }
          if (
            response.data.jdbCardNumber1 !== null &&
            response.data.card_active_reject === 'Activated' &&
            response.data.user_activated === false
          ) {
            navigate('/cardisactivate');
            return;
          }
          if (response.data.card_activated == 2) {
            return;
          }
          if (response.data.card_activated == 1) {
          }
          if (
            (response.data.kycStatus === '1' ||
              response.data.kycStatus === 1) &&
            response.data.jdbCardNumber1 &&
            (response.data.card_active_reject === null ||
              response.data.card_active_reject === 'Rejected')
          ) {
            navigate('/card-submit');
            return;
          }
          if (
            response.data.jdbCardNumber1 &&
            response.data.card_active_reject === 'inprogress'
          ) {
            navigate('/cardisactivate');
            return;
          }
        }
      })
      .catch(function (error) {});
  };

  const connectClickHandler2 = async () => {
    if (ethereum && ethereum.isMetaMask) {
      const web3 = await getWeb3();
      const chainId = await web3.eth.getChainId();
      console.log(chainId, 'chainnn');
      if (chainId == '56') {
        setCurrentChain('BSC');
        localStorage.setItem('currentChain', 'BSC');
      } else if (chainId == '1') {
        setCurrentChain('ETH');
        localStorage.setItem('currentChain', 'ETH');
      } else {
        toast.error('Connect with eth/bsc chain');
      }

      connect()
        .then((res) => {
          localStorage.setItem('metamaskId', res[0]);

          whiteListHandler();
          loginHandler();

          setState((state) => ({
            ...state,
            address: res[0],
            isConnected: true,
          }));
          LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
          LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
            name: res[0],
            email: 'theone',
          });
        })
        .catch((err) => {
          toast.error(err.message);
        });

      // ethereum
      //   .request({ method: "eth_requestAccounts" })
      //   .then((result) => getData(ethereum, result))
      //   .then((result) => {
      //     const [account, network, symbol] = result;

      //     localStorage.setItem("primary", account);
      //     whiteListHandler()
      //     loginHandler()
      //     setState((state) => ({
      //       ...state,
      //       address: account,
      //       network,
      //       symbol,
      //       isConnected: true,
      //     }));
      //   })
      //   .catch((error) => {

      //   });
    } else if (status !== 'unavailable') {
      connect()
        .then((res) => {
          console.log(res, 'res');

          localStorage.setItem('metamaskId', res[0]);

          whiteListHandler();
          loginHandler();
          setState((state) => ({
            ...state,
            address: res[0],
            isConnected: true,
          }));
          LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
          LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
            name: res[0],
            email: 'theone',
          });
        })
        .catch((err) => {
          console.error(err, 'fsgvb');
          toast.error(err.message);
        });
    } else {
      toast.error('Please install MetaMask!');
      setState((state) => ({ ...state, modal: true }));
    }
  };

  const connectClickHandler = (val) => {
    if (val === 'phantom') {
      if (getCookie('primary') && getCookie('token')) {
        return;
      }

      if (solana && solana.isPhantom) {
        solana
          .connect()
          .then(() => {
            const publicKey = solana.publicKey.toString();

            localStorage.setItem('phantomId', publicKey);

            localStorage.setItem('currentChain', 'SOL');

            whiteListHandler();
            loginHandler();
            setState((state) => ({
              ...state,
              address: publicKey,
              isConnected: true,
            }));
            LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
            LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
              name: publicKey,
              email: 'theone',
            });
          })
          .catch((err) => {
            console.error(err, 'fsgvb');
            toast.error(err.message);
          });
      } else {
        toast.error('Please install Phantom !');
        setState((state) => ({ ...state, modal: true }));
      }
    } else {
      connectClickHandler2();
    }
  };

  const whiteListHandler = () => {
    axios
      .get(
        `${URL}/users/whiteList?userAddress=${getCookie(
          'primary'
        )}&email=${getCookie('email')}`,
        {
          headers: {
            Authorization: getCookie('token'),
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          // updateAllCoins()
          setWhiteListCheck(true);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        toast.success(error);
      });
  };

  const showdropdown = () => {
    setVisible(!visible);
  };

  return (
    <nav class='navbar navbar-expand-lg navbar-dark1  pb-0 header-new '>
      <div class='container-fluid '>
        <span class='navbar-brand'>
          <img src={LOGO} alt='' />
        </span>

        <div class='navbar-collapse' id='navbarResponsive'>
          <ul class='navbar-nav ms-auto '>
            <li class='nav-item first-nav d-flex align-items-center  justify-content-between'>
              {getCookie('primary') && (
                <button
                  class='navbar-toggler'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarResponsive'
                  aria-controls='navbarResponsive'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <span class='navbar-toggler-icon d-flex align-items-center justify-content-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='50'
                      height='50'
                      fill='white'
                      class='bi bi-list'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
                      />
                    </svg>
                  </span>
                </button>
              )}

              <div className='d-sm-flex flex-sm-column align-items-center flex-md-row mx-md-4 '>
                {!disableForm && (
                  <div
                    className='position-relative'
                    style={{ marginRight: '20px' }}
                  >
                    {getCookie('primary') && getCookie('token') && (
                      <button
                        className='chainbutton f-sol'
                        onClick={() => {
                          setClickData(!clickData);
                        }}
                      >
                        {localStorage.currentChain === ''
                          ? 'Chain'
                          : localStorage.currentChain}
                      </button>
                    )}
                    {clickData && (
                      <div
                        className='position-absolute absolute-container'
                        style={{
                          backgroundColor: '#000',
                          zIndex: 999,
                          width: '100%',
                          textAlign: 'center',
                          margin: '5px auto',
                          padding: '5px auto',
                        }}
                      >
                        {localStorage.currentChain.toLowerCase() === 'eth' ||
                        localStorage.currentChain.toLowerCase() === 'sol' ? (
                          <div
                            style={{
                              backgroundColor: '#000',
                              color: '#fff',
                            }}
                          >
                            <img
                              src={
                                'https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png'
                              }
                              width='20px'
                            />
                            <span
                              onClick={() => {
                                handleNetworkSwitch('bsc');
                                setClickData(!clickData);
                              }}
                            >
                              BSC
                            </span>
                          </div>
                        ) : (
                          ''
                        )}

                        {localStorage.currentChain.toLowerCase() === 'bsc' ||
                        localStorage.currentChain.toLowerCase() === 'sol' ? (
                          <div
                            style={{
                              marginTop: '20px',
                              backgroundColor: '#000',
                              color: '#fff',
                            }}
                          >
                            <img src={ETH} width='20px' />
                            <span
                              onClick={() => {
                                handleNetworkSwitch('eth');
                                setClickData(!clickData);
                              }}
                            >
                              ETH
                            </span>
                          </div>
                        ) : (
                          ''
                        )}

                        {localStorage.currentChain.toLowerCase() === 'bsc' ||
                        localStorage.currentChain.toLowerCase() === 'eth' ? (
                          <div
                            style={{
                              marginTop: '20px',
                              backgroundColor: '#000',
                              color: '#fff',
                            }}
                          >
                            <img src={SOL} width='20px' />
                            <span
                              onClick={() => {
                                handleNetworkSwitch2('SOL');
                                setClickData(!clickData);
                              }}
                            >
                              SOL
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </div>
                )}

                <div
                  className='btn btn-w padded position-relative mob-sol'
                  onClick={showdropdown}
                  style={{ width: '200px' }}
                >
                  <span className='f-sol '>
                    {getCookie('primary') &&
                    getCookie('token') &&
                    getCookie('currentChain') === 'SOL'
                      ? getCookie('phantomId').slice(0, 6) +
                        '...' +
                        getCookie('phantomId').substr(
                          getCookie('phantomId').length - 4
                        )
                      : getCookie('metamaskId')
                      ? getCookie('metamaskId').slice(0, 6) +
                        '...' +
                        getCookie('metamaskId').substr(
                          getCookie('metamaskId').length - 4
                        )
                      : 'Connect'}
                  </span>
                  {visible && !getCookie('primary') && (
                    <div
                      className='position-absolute'
                      style={{
                        bottom: '-100px',
                        left: 0,
                        backgroundColor: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '200px',
                        borderRadius: '5px',
                        backgroundColor: '#fff',
                        border: '2px solid #000',
                      }}
                    >
                      <div
                        style={{
                          borderBottom: '2px solid #000',
                          width: '100%',
                          fontSize: '14px',
                          padding: '10px 0',
                        }}
                        className='hovbtn'
                        onClick={() => {
                          connectClickHandler('phantom');
                        }}
                      >
                        Phantom
                      </div>
                      <div
                        style={{
                          width: '100%',
                          fontSize: '14px',
                          padding: '10px 0',
                        }}
                        className='hovbtn'
                        onClick={() => {
                          connectClickHandler('phnatom');
                        }}
                      >
                        Metamask
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </li>

            {getCookie('primary') && localStorage.getItem('token') ? (
              <>
                <li
                  class='nav-item text-center'
                  style={{ display: showMenu ? 'block' : 'none' }}
                >
                  <span
                    class={
                      location.pathname === '/' ||
                      location.pathname === '/dashboard'
                        ? 'nav-link active1'
                        : 'nav-link'
                    }
                    onClick={() => {
                      navigate('/');
                      checktoWhitelist();
                    }}
                  >
                    Home
                  </span>
                </li>

                {disableForm ? null : (
                  <li
                    class='nav-item text-center'
                    style={{ display: showMenu ? 'block' : 'none' }}
                  >
                    <span
                      class={
                        location.pathname === '/card-load-home'
                          ? 'nav-link active1'
                          : 'nav-link'
                      }
                      style={{ width: '100px' }}
                      onClick={() => {
                        navigate('/card-load-home');
                        checktoWhitelist();
                        roleContext.updateContext({ Loading: true });
                        window.location.reload();
                      }}
                    >
                      Load Card
                    </span>
                  </li>
                )}

                {
                  <li
                    class='nav-item text-center'
                    style={{ display: showMenu ? 'block' : 'none' }}
                  >
                    <span
                      class={
                        location.pathname === '/card-form' ||
                        location.pathname === '/card-activate' ||
                        location.pathname === '/card-submit' ||
                        location.pathname === '/card-load' ||
                        location.pathname === '/cardisactivate' ||
                        location.pathname === '/cardpurchase' ||
                        location.pathname === '/card-process'
                          ? 'nav-link active1'
                          : 'nav-link'
                      }
                      onClick={() => {
                        if (
                          roleContext.affiliate &&
                          (roleContext.card_purchase === '1' ||
                            roleContext.card_purchase === 1) &&
                          roleContext.jdbCardNumber1 === null &&
                          roleContext.card_status === 'paid'
                        ) {
                          navigate('/card-process');
                          return;
                        }
                        if (
                          roleContext.card_applied === 0 &&
                          (roleContext.card_status === true ||
                            roleContext.card_status === 'paid')
                        ) {
                          navigate('/card-form');
                        }
                        if (
                          roleContext.jdbCardNumber1 &&
                          roleContext.card_active_reject === 'inprogress'
                        ) {
                          navigate('/cardisactivate');
                          return;
                        }
                        if (roleContext.card_activated == 2) {
                          navigate('/card-load');
                        } else if (roleContext.card_activated == 1) {
                        } else if (
                          ((roleContext.card_purchase === '1' ||
                            roleContext.card_purchase === 1) &&
                            roleContext.jdbCardNumber1) ||
                          roleContext.card_active_reject === 'Rejected'
                        ) {
                          navigate('/card-submit');
                        } else if (roleContext.card_applied == 2) {
                          navigate('/card-load');
                        } else if (
                          roleContext.card_status == null ||
                          roleContext.card_status == false
                        ) {
                          navigate('/cardpurchase');
                        }
                      }}
                    >
                      {roleContext.card_activated == 2 ? 'Transaction' : 'Card'}
                    </span>
                  </li>
                }

                <li
                  class='nav-item text-center'
                  style={{ display: showMenu ? 'block' : 'none' }}
                >
                  <span
                    class='nav-link'
                    onClick={() => {
                      //deleteCookie('token');
                      if (localStorage.getItem('staff')) {
                        navigate('/staff');
                      } else {
                        navigate('/');
                      }
                      deleteAll();
                      window.location.reload();
                    }}
                  >
                    Logout
                  </span>
                </li>
              </>
            ) : (
              ''
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
