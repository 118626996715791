import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import './Header.scss';
import Banding from '../../Sass/img/Branding.png';
import { useNavigate, useLocation } from 'react-router';

import {
  deleteAll,
  deleteCookie,
  getCookie,
  setCookie,
} from '../../Utils/cookieHandling';
import axios from 'axios';
import { URL } from '../../Utils/url';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import Solswipe from '../../Sass/img/LOGO1.svg';
import Twitter from '../../Sass/img/twitterwhite.png';
import Discord from '../../Sass/img/discordwhite.png';
import Telegram from '../../Sass/img/telegramwhite.png';
import { FaDiscord, FaTelegram, FaTwitter } from 'react-icons/fa';

export default function LandingPageHeader(props) {
  const roleContext = useContext(UserRoleContext);

  //let { ethereum } = window;
  const location = useLocation();
  const navigate = useNavigate();
  const [disableForm, setDisableForm] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [state, setState] = useState({
    modal: false,
    address: '',
    balance: '',
    isPhantomInstall: false,
    isConnected: false,
  });

  return (
    <nav class='navbar navbar-expand-lg navbar-dark1 pb-0 header-new'>
      <div class='container-fluid px-lg-8'>
        <div
          class='navbar-brand d-flex justify-content-between  align-items-center flex-wrap '
          onClick={() => navigate('/')}
        >
          <img
            src={Solswipe}
            alt=''
            style={{ width: window.innerWidth > 500 ? '120px' : '80px' }}
          />
        </div>
        <div className='d-flex items-center'>
          <Link className='mx-2'>
            <FaDiscord
              size={window.innerWidth > 500 ? '40px' : '30px'}
              color='#fff'
            />
          </Link>
          <Link className='mx-2'>
            <FaTwitter
              size={window.innerWidth > 500 ? '40px' : '30px'}
              color='#fff'
            />
          </Link>
          <Link className='mx-2'>
            <FaTelegram
              size={window.innerWidth > 500 ? '40px' : '30px'}
              color='#fff'
            />
          </Link>
        </div>
      </div>
    </nav>
  );
}
